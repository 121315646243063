@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/montserrat/montserrat-thin.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/montserrat/montserrat-thin-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/montserrat/montserrat-extra-light.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/montserrat/montserrat-extra-light-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/montserrat/montserrat-light.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/montserrat/montserrat-light-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/montserrat/montserrat-regular.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/montserrat/montserrat-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/montserrat/montserrat-medium.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/montserrat/montserrat-medium-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/montserrat/montserrat-semi-bold.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/montserrat/montserrat-semi-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/montserrat/montserrat-bold.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/montserrat/montserrat-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/montserrat/montserrat-extra-bold.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/montserrat/montserrat-extra-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/montserrat/montserrat-black.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/montserrat/montserrat-black-italic.woff2') format('woff2');
}
